<template>
	<table :class="size">
		<colgroup v-if="size == 'small'">
			<col style="width: 106px; text-align: center" />
			<col style="width: 114px" />
			<col style="width: 60px" />
			<col style="width: 114px" />
			<col style="width: 325px; text-align: left" />
			<col style="width: 70px" />
		</colgroup>
		<colgroup v-else>
			<col style="width: 180px; text-align: center" />
			<col style="width: 228px" />
			<col style="width: 60px" />
			<col style="width: 228px" />
			<col style="width: 402px; text-align: left" />
			<col style="width: 100px" />
		</colgroup>
		<thead>
			<tr>
				<th>赛事/名称</th>
				<th style="text-align: right; padding-right: 50px">主队</th>
				<th></th>
				<th style="text-align: left; padding-left: 50px">客队</th>
				<th style="text-align: left; padding-left: 20px">主播</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="match in lists" :key="match.tournament_id">
				<td>
					<span class="league">
						<span class="league-type">{{ match.tournament_type | tournamentType }}</span>
						<span>{{ match.league_name_zh  | strLength}}</span>
					</span>
					<br />
					<span class="time">{{ match.match_time || match.start_time }}</span>
				</td>
				<td>
					<div class="team" style="justify-content: flex-end; text-align: right;">
						<span>{{ match.home_team_zh }}</span>
						<img :src="match.home_logo || '0'" @error="onTeamLogoError($event, match.tournament_type)" />
					</div>
				</td>
				<td v-if="match.state == 0">未开始</td>
				<td v-else><span class="inprogress">进行中</span></td>
				<td>
					<div class="team">
						<img :src="match.away_logo || '0'" @error="onTeamLogoError($event, match.tournament_type)" />
						<span>{{ match.away_team_zh }}</span>
					</div>
				</td>
				<td>
					<div class="anchors" v-if="match.anchor.length === 0">
						<div class="anchor"><img src="../assets/img/undetermined.png" /></div>
					</div>
					<div class="anchors" v-else>
						<a
							class="anchor"
							v-for="anchor in match.anchor || []"
							:key="anchor.uid"
							:href="`/detail/${match.tournament_type}/${match.tournament_id}/${anchor.member_id}`"
							target="_blank"
						>
							<img :src="anchor.face || '0'" @error="onAvatarError" />
							<span :title="anchor.username">{{ anchor.username }}</span>
						</a>
					</div>
				</td>
				<td>
					<a href="javascript:;" :class="['appointment', match.is_reservation == 1 ? '' : 'active']" @click="$emit('appointment', match)">
						{{ match.is_reservation == 1 ? '已预约' : '预约' }}
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'wsScheduleTable',
	props: {
		size: {
			type: String,
			default: ''
		},
		lists: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {};
	},
	filters: {
		tournamentType(val) {
			return ['', '足球', '篮球', '其他', '网球', '排球', '台球', '羽毛球', '电竞'][val]
		},
        strLength(val){
            console.log(val);
            if(val && val.length > 7){
               return  val.slice(0,7)+'......'
            }else {
                return val
            }
        }
	}
};
</script>

<style lang="less">

.schedule {
	background-color: #ffffff;
	&-header {
		position: relative;
		height: 72px;
		padding-top: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		color: var(--primary-color);
		font-size: 20px;
		line-height: 1;
		&::before {
			content: '';
			height: 20px;
			border-left: var(--primary-color) 4px solid;
			margin-right: 10px;
		}
	}

	&-body {
		table {
			width: 100%;
			border: 1px solid #e4e4e4;
			white-space: nowrap;
			th {
				text-align: center;
				height: 50px;
				background: var(--primary-color);
				font-size: 16px;
				color: #ffffff;
			}
			td {
				text-align: center;
				height: 72px;
				border-bottom: 1px solid #eef3f8;
			}

			&.small {
				.anchors .anchor {
					width: 65px;
				}
				.league-type {
					display: none;
				}
				.team {
					max-width: 150px;
				}
				.team img {
					margin: 0 2px;
				}
			}
		}
		.league {
			color: #737d92;
			font-size: 14px;
			&-type {
				margin-right: 4px;
			}
		}
		.time {
			font-size: 20px;
			font-weight: 500;
			color: #333333;
		}

		.inprogress {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 500;
			color: #ed4b65;
			&::before {
				content: '';
				width: 8px;
				height: 8px;
				background: #ed4b65;
				border-radius: 50%;
				margin-right: 4px;
			}
		}

		.anchors {
			text-align: left;
			display: flex;
			flex-direction: row;
			align-items: center;

			.anchor {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: #737d92;
				width: 80px;
				img {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					display: block;
					margin-bottom: 4px;
				}
				span {
					max-width: 74px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}
		}
		.team {
			text-align: left;
			display: flex;
			flex-direction: row;
			align-items: center;
			max-width: 228px;
			span {
				flex: 1;
				min-width: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 16px;
				font-weight: 500;
			}
			img {
				display: block;
				width: 38px;
				height: 38px;
				border-radius: 50%;
				margin: 0 8px;
			}
		}
		.appointment {
			display: block;
			width: 60px;
			color: #cacaca;
			border: 2px solid #cacaca;
			border-radius: 16px;
			line-height: 28px;
			&.active {
				line-height: 32px;
				background: var(--active-bgcolor);
				border: 0;
				color: #ffffff;
			}
		}
	}

	.empty {
		height: 72px;
		display: flex;
		align-items: center;
		flex-direction: row;
		padding-left: 60px;
		font-size: 20px;
		color: #4f5a70;
	}
}
</style>
